import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Graph from './Graph/LazyRoute';

import './App.css';

const App = () => (
  <div className="App">
    <Router>
      <Routes>
        <Route path="/graph" element={<Graph />} />
        <Route
          path="/"
          element={(
            <div className="centered">
              <p>
                No embed tool specified.
              </p>
              <p>
                <a href="/example.html">Go to examples.</a>
              </p>
            </div>
          )}
        />
      </Routes>
    </Router>
  </div>
);

export default App;
